/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "ja", name: "日本語", direction: "ltr" }
];

export const firebaseConfig = {
  apiKey: "AIzaSyDiWkL8rE9odAI7pSpY74fLje2DNAtt0F0",
  authDomain: "tenova-admin.firebaseapp.com",
  databaseURL: "https://tenova-admin.firebaseio.com",
  projectId: "tenova-admin",
  storageBucket: "tenova-admin.appspot.com",
  messagingSenderId: "627486810824",
  appId: "1:627486810824:web:0f42f01da930abb2ebbbfd",
  measurementId: "G-RSJMDFC2WS"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey="__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
